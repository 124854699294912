const apiLocation = process.env.VUE_APP_API_SOURCE

export const getRequest = async (path) => {
    const response = await fetch(`${apiLocation}/${path}`);

    if (response.status === 200) {
        return response.json();
    } else {
        return false;
    }
}

export const sendMessage = async (object) => {
   try {
        const response = await fetch(`${apiLocation}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        });
        return {
            status: response.status,
            response: await response.json()
        }
    } catch (error) {
        return {
            status: 500,
            response: 'error'
        }
    }
}