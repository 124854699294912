<template>
  <v-container>
    <div class="script-placeholder"></div>
    <v-row justify="center" class="mb-3">
      <v-col cols="12" class="d-flex flex-column justify-center">
        <h1 class="text-center text-h2">Contact</h1>
      </v-col>
      <hr class="smallhr" />
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="10">
        <v-card>
          <v-card-title class="d-flex flex-column">
            <h2 class="text-h5 font-weight-light text-justify">
              Une question? Une demande de devis ? N'hésitez pas à remplir le
              formulaire ci-dessous!
            </h2>
            <v-divider></v-divider>
            <p class="text-subtitle-1 font-weight-light">
              Je reviendrai vers vous très rapidement
            </p>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="firstName"
                    id="firstName"
                    :rules="firstNameRules"
                    :counter="50"
                    label="Prénom"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="lastName"
                    id="lastName"
                    :rules="lastNameRules"
                    :counter="50"
                    label="Nom"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model="email"
                    id="email"
                    :rules="emailRules"
                    label="E-mail"
                    prepend-inner-icon="mdi-email-outline"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field
                    v-model="company"
                    id="company"
                    :counter="50"
                    :rules="companyRules"
                    label="Société (facultatif)"
                    prepend-inner-icon="mdi-briefcase"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <vue-tel-input-vuetify
                    v-model="phone"
                    defaultCountry="fr"
                    placeholder="Téléphone (facultatif)"
                    label=""
                  ></vue-tel-input-vuetify>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="subject"
                    id="subject"
                    :rules="subjectRules"
                    label="Sujet du message..."
                    :counter="100"
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    label="Votre message..."
                    v-model="text"
                    :counter="1000"
                    :rules="textRules"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="4">
                <div
                  class="g-recaptcha"
                  data-sitekey="6LetJ-IZAAAAAENF9y1Okgw4-MzzqWKEHXW_J_gf"
                ></div>
                <v-alert
                  type="warning"
                  v-model="recaptchaMessage"
                  origin="top left"
                  transition="slide-y-reverse-transition"
                >
                  Veuillez valider le captcha avant d'envoyer le formulaire!
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              :disabled="!valid"
              depressed
              x-large
              color="secondary darken-1"
              @click="sendRoutine"
            >
              Envoyer!
              <v-icon v-if="!loading" class="ml-3" large
                >mdi-email-outline</v-icon
              >
              <v-progress-circular v-else indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="resultDialog" width="500" max-width="80%">
      <v-card>
        <v-alert
          :type="result.alert"
          class="ma-0"
          :dismissible="resultStatus !== 200"
          @input="resetCaptcha"
        >
          {{ result.message }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { sendMessage } from "@/js/fetchRequests";

export default {
  name: "Contact",
  data() {
    return {
      valid: false,
      resultDialog: false,
      resultStatus: 0,
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      phone: null,
      subject: "",
      text: "",
      firstNameRules: [
        (input) => !!input || "Veuillez entrer votre prénom",
        (input) =>
          input.length < 50 || "Votre prénom doit faire moins de 50 caractères",
        (input) => input.length > 1 || "Votre prénom semble un peu court",
      ],
      lastNameRules: [
        (input) => !!input || "Veuillez entrer votre nom",
        (input) => input.length > 1 || "Votre nom semble un peu court",
        (input) =>
          input.length < 50 || "Votre nom doit faire moins de 50 caractères",
      ],
      emailRules: [
        (v) => !!v || "Veuillez entrer votre e-mail",
        (v) => /.+@.+\..+/.test(v) || "Entrez un e-mail valide",
      ],
      companyRules: [
        (input) =>
          input.length < 50 ||
          "Votre société doit faire moins de 50 caractères",
      ],
      subjectRules: [
        (v) => !!v || "Dites moi de quoi parle votre message svp...",
        (v) => v.length > 5 || "Dites m'en un peu plus!",
        (v) => v.length < 100 || "Ok pas tant pas tant!",
      ],
      textRules: [
        (v) => !!v || "Dites moi quelque chose",
        (v) => v.length > 30 || "Dites m'en un peu plus!",
        (v) => v.length < 1000 || "Ok pas tant pas tant!",
      ],
      recaptchaMessage: false,
    };
  },
  computed: {
    result() {
      switch (this.resultStatus) {
        case 200:
          return {
            title: "Message envoyé!",
            message:
              "Le message a été correctement envoyé, vous allez être redirigé vers l'accueil",
            alert: "success",
          };
        case 403:
          return {
            title: "Erreur de Captcha",
            message: "La validation du captcha a écouhée, veuillez réessayer",
            alert: "warning",
          };
        default:
          return {
            title: "Erreur Serveur",
            message: "Un problème s'est produit au niveau du serveur",
            alert: "error",
          };
      }
    },
  },
  mounted() {
    const placeholder = this.$el.querySelector(".script-placeholder");
    const script = document.createElement("script");
    script.setAttribute("defer", true);
    script.setAttribute("async", true);
    script.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    placeholder.appendChild(script);
  },
  methods: {
    async sendRoutine() {
      const response = document.querySelector(".g-recaptcha-response");
      if (response.value.length) {
        this.loading = true;
        const messageObject = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          company: this.company ? this.company : "Non renseignée",
          tel: this.phone ? this.phone : "Non renseigné",
          subject: this.subject,
          text: this.text,
          captcha: response.value,
        };
        const serverResponse = await sendMessage(messageObject);
        this.loading = false;
        this.resultStatus = serverResponse.status;
        this.resultDialog = true;
        if (this.resultStatus === 200) {
          setTimeout(() => {
            this.$router.push("/");
          }, 4000);
        }
      } else {
        this.recaptchaMessage = true;
        setTimeout(() => {
          this.recaptchaMessage = false;
        }, 4000);
      }
    },
    resetCaptcha() {
      window.grecaptcha.reset();
      this.resultDialog = false;
    },
  },
  beforeMount() {
    window.document.title = "Développeur d'Applications à Toulouse - Contact";
  },
};
</script>

<style lang="scss" scoped>
.col {
  & h1 {
    font-variant: small-caps;
  }
}

.smallhr {
  width: 70%;
}

.v-card {
  &__title {
    word-break: break-word;
  }
}
</style>